@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body * {
    @apply font-poppins;
  }
  .centered-container {
    @apply w-full mx-auto px-4 md:px-8 lg:px-10;
  }
}

.ant-message.ant-message-top {
  @apply inset-y-2 flex flex-col-reverse;
}

.ant-picker-ok > button.ant-btn:not([disabled]) {
  @apply !bg-primary !text-white hover:!bg-primary-hover;
}

.custom-pagination .ant-pagination {
  @apply flex items-center justify-center flex-wrap gap-y-2 gap-1;
}
.custom-pagination .ant-pagination li {
  @apply flex items-center justify-center border border-[#DFE3E8] rounded-lg text-14 font-bold;
}
.custom-pagination .ant-pagination li:not(.ant-pagination-item-active) a {
  @apply text-[#212B36];
}
.custom-pagination .ant-pagination li.ant-pagination-item-active {
  @apply border-primary;
}
.custom-pagination .ant-pagination li.ant-pagination-disabled {
  @apply border-[#919EAB] bg-[#919EAB] opacity-50;
}
.custom-pagination .ant-pagination li.ant-pagination-item-active a {
  @apply text-primary;
}
.custom-pagination .ant-pagination li:hover:not(.ant-pagination-disabled) {
  @apply border-primary bg-white;
}

/* Custom dropdown styles */
.custom-select.ant-select:has(.ant-select-selection-item) {
  @apply w-fit;
}
.custom-select.ant-select > div.ant-select-selector {
  @apply border-2 flex items-center gap-3;
}

.custom-select.ant-select > div.ant-select-selector .ant-select-selection-item {
  @apply text-14 leading-6 font-normal text-black;
}

.ant-select-dropdown {
  @apply p-0;
}
.ant-select-dropdown .rc-virtual-list-holder-inner {
  @apply divide-y divide-[#E6E8F0];
}
.ant-select-dropdown .ant-select-item-option {
  @apply py-3 text-12 font-semibold text-[#8F95B2];
}
.ant-select-dropdown .ant-select-item-option.ant-select-item-option-active {
  @apply bg-[#EBF0FF] rounded-none;
}
.ant-select-dropdown .ant-select-item-option.ant-select-item-option-selected {
  @apply text-primary relative;
}
.ant-select-dropdown
  .ant-select-item-option.ant-select-item-option-selected::before {
  @apply content-[""] absolute inset-y-0 w-1 bg-primary start-0 rounded-e-lg;
}

/* Table styles */
.custom-table-container .ant-table-thead > tr > :is(th, td) {
  @apply px-4 bg-transparent border-b border-[E6E8F0] font-semibold text-12 text-[#283641] uppercase;
}
.custom-table-container .ant-table-tbody > tr > td {
  @apply px-4 border-b-2 border-cmsBg;
}
.custom-table-container
  .ant-table-thead
  > tr
  > :is(th, td):not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  content: none !important;
}
.custom-table-container .ant-table-column-sorters {
  justify-content: initial !important;
}
.custom-table-container .ant-table-column-sorters .ant-table-column-title {
  flex: initial !important;
}
.custom-table-container .ant-table-cell > span {
  @apply text-14 text-[#4d515a] font-normal;
}
.custom-table-container .ant-table-row .ant-table-cell:nth-child(2) {
  @apply pe-0;
}
.custom-table-container .ant-table-row:nth-child(odd) {
  @apply bg-[#f4f7fc];
}

.ant-message .custom-warning-message .ant-message-notice-content {
  @apply bg-white flex w-fit mx-auto border border-danger;
}
.ant-message .custom-warning-message .ant-message-custom-content {
  @apply flex items-center gap-2 capitalize text-[#283641];
}
.ant-message .custom-success-message .ant-message-notice-content {
  @apply bg-white flex w-fit mx-auto border border-[#50CD89];
}
.ant-message .custom-success-message .ant-message-custom-content {
  @apply flex items-center gap-2 capitalize text-[#283641];
}

::-webkit-scrollbar {
  @apply w-2 h-2;
}
::-webkit-scrollbar-thumb {
  @apply rounded bg-gray-300;
}
::-webkit-scrollbar-track {
  @apply bg-white;
}
